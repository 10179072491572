import { unit_type, equals, class_type } from "./fable_modules/fable-library.3.7.17/Reflection.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.66.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { RouterModule_router, RouterModule_urlSegments, RouterModule_encodeParts } from "./fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { tail, head, isEmpty, cons, empty, ofArray } from "./fable_modules/fable-library.3.7.17/List.js";
import { RegisterAppResponse$reflection, RegisterAppRequest$reflection, RegisterAppRequest } from "./OAuth.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { CaseStrategy } from "./fable_modules/Thoth.Json.6.0.0/Types.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.2.2.2/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.17/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/./Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.6.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.17/MapUtil.js";
import { map, defaultArg } from "./fable_modules/fable-library.3.7.17/Option.js";
import { toString } from "./fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.6.0.0/./Encode.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF } from "./fable_modules/Thoth.Json.6.0.0/./Decode.fs.js";
import { fromString } from "./fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.6.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.17/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.66.0/./Interop.fs.js";
import { singleton, delay, toList } from "./fable_modules/fable-library.3.7.17/Seq.js";

export class Components {
    constructor() {
    }
}

export function Components$reflection() {
    return class_type("App.Components", void 0, Components);
}

export function Components_get_baseUrl() {
    return "https://localhost:8081";
}

export function Components_MainPage() {
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const domain = patternInput[0];
    const children = ofArray([createElement("label", {
        htmlFor: "domain",
        children: "Instance: ",
    }), createElement("input", {
        name: "domain",
        title: "Instance Domain",
        placeholder: "mastodon.social",
        value: domain,
        onChange: (ev) => {
            patternInput[1](ev.target.value);
        },
    }), createElement("button", {
        children: "Submit",
        onClick: (_arg_3) => {
            PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url, data_2, caseStrategy_1, extra_1;
                const oauthRelativeUrl = RouterModule_encodeParts(ofArray(["oauth", "redirect"]), 2);
                const data = new RegisterAppRequest("incognitum", `${Components_get_baseUrl()}/${oauthRelativeUrl}`, "read", Components_get_baseUrl());
                return ((url = (`https://${domain}/api/v1/apps`), (data_2 = data, (caseStrategy_1 = (new CaseStrategy(1)), (extra_1 = (void 0), (() => {
                    let properties_2, headers_2;
                    try {
                        const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_2, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(RegisterAppRequest$reflection(), caseStrategy_1, extra_1)(data_1_1))), properties_2), data_2), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url, properties_3).then((_arg) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(void 0, Auto_generateBoxedDecoderCached_79988AEF(RegisterAppResponse$reflection(), caseStrategy_1, extra_1)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                let matchValue;
                                return Promise.resolve(equals(RegisterAppResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                    }
                })()))))).then((_arg_2) => (Promise.resolve(_arg_2)));
            }));
        },
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Components_HelloWorld() {
    return createElement("h1", {
        children: ["Hello World"],
    });
}

export function Components_Counter() {
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const count = patternInput[0] | 0;
    const children = ofArray([createElement("h1", {
        children: [count],
    }), createElement("button", {
        onClick: (_arg) => {
            patternInput[1](count + 1);
        },
        children: "Increment",
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Components_Router() {
    let elements;
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.pathname + window.location.search, 2));
    const currentUrl = patternInput[0];
    console.log(currentUrl);
    return RouterModule_router(createObj(ofArray([["hashMode", 2], ["onUrlChanged", patternInput[1]], (elements = toList(delay(() => ((!isEmpty(currentUrl)) ? ((head(currentUrl) === "hello") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(Components_HelloWorld, null)) : singleton(createElement("h1", {
        children: ["Not found"],
    }))) : ((head(currentUrl) === "counter") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(Components_Counter, null)) : singleton(createElement("h1", {
        children: ["Not found"],
    }))) : singleton(createElement("h1", {
        children: ["Not found"],
    })))) : singleton(createElement(Components_MainPage, null))))), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

