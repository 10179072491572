import { Union, Record } from "./fable_modules/fable-library.3.7.17/Types.js";
import { union_type, record_type, string_type } from "./fable_modules/fable-library.3.7.17/Reflection.js";

export class RegisterAppRequest extends Record {
    constructor(client_name, redirect_uris, scopes, website) {
        super();
        this.client_name = client_name;
        this.redirect_uris = redirect_uris;
        this.scopes = scopes;
        this.website = website;
    }
}

export function RegisterAppRequest$reflection() {
    return record_type("App.OAuth.RegisterAppRequest", [], RegisterAppRequest, () => [["client_name", string_type], ["redirect_uris", string_type], ["scopes", string_type], ["website", string_type]]);
}

export class RegisterAppResponse extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SuccessfulAppRegistration", "AppRegistrationError"];
    }
}

export function RegisterAppResponse$reflection() {
    return union_type("App.OAuth.RegisterAppResponse", [], RegisterAppResponse, () => [[], []]);
}

export class SuccessfulAppRegistration extends Record {
    constructor(id, name, website, redirect_uri, client_id, client_secret, vapid_key) {
        super();
        this.id = id;
        this.name = name;
        this.website = website;
        this.redirect_uri = redirect_uri;
        this.client_id = client_id;
        this.client_secret = client_secret;
        this.vapid_key = vapid_key;
    }
}

export function SuccessfulAppRegistration$reflection() {
    return record_type("App.OAuth.SuccessfulAppRegistration", [], SuccessfulAppRegistration, () => [["id", string_type], ["name", string_type], ["website", string_type], ["redirect_uri", string_type], ["client_id", string_type], ["client_secret", string_type], ["vapid_key", string_type]]);
}

export class AppRegistrationError extends Record {
    constructor(error) {
        super();
        this.error = error;
    }
}

export function AppRegistrationError$reflection() {
    return record_type("App.OAuth.AppRegistrationError", [], AppRegistrationError, () => [["error", string_type]]);
}

